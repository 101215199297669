import { isNullUndefinedEmpty, spinnerHtml } from '../helpers/helpers';

import { checkTableSwipeDisplay } from '../common/tables';
import { displayErrorMessage } from '../helpers/displayError';
import { getContactHTML } from './contacts';
import { svgInliner } from '../common/svgInliner';
import userStateManager from '../helpers/stateManager';

export const component = () => {
    //Handles rendering all elements on /my-bgu

    var $myBguSelect = $('#my-bgu-select');
    var $myBgu = $('#my-bgu-details-wrapper');
    var $myBguContacts = $('#my-bgu-contacts');
    var $myBguMembers = $('#my-bgu-members-wrapper'); //BGUMembers section can be removed and added as needed via CMS

    if ($myBgu.length && $myBguSelect.length && $myBguContacts.length) {
        userStateManager.getUserProfileData(function (data) {
            if (data) {
                try {
                    if (data.apiProblemOccured) {
                        displayErrorMessage($myBgu);
                        displayErrorMessage($myBguContacts);
                        $myBguSelect.parents('.callout-box').hide();
                        return;
                    }

                    setupBguPage(data.ProfileInfo);
                } catch (error) {
                    console.error('There was an issue generating the My BGUs page: ', error);
                    displayErrorMessage($myBgu);
                    displayErrorMessage($myBguContacts);
                    $myBguSelect.parents('.callout-box').hide();
                }
            }
        });
    } else if ($myBgu.length || $myBguSelect.length || $myBguContacts.length) {
        console.warn('Some, but not all My BGU widgets were found. Please check the page is configured correctly');
        if ($myBguSelect.length === 0) {
            console.warn('My BGU selector dropdown widget may be missing');
        }
        if ($myBgu.length === 0) {
            console.warn('My BGU Details widget may be missing');
        }
        if ($myBguContacts.length === 0) {
            console.warn('My BGU Contacts widget may be missing');
        }
    }

    function setupBguPage(content) {
        const bgus = !isNullUndefinedEmpty(content) && !isNullUndefinedEmpty(content.BGUs) ? content.BGUs : [];

        //Set up the select based on bgu length
        if (bgus.length > 1) {
            const optionsHtml = bgus.map((bgu, index) => {
                return `
                    <option value="${index}">${bgu.BguName}</option>
                `;
            });
            $myBguSelect.html(optionsHtml);
            $myBguSelect.on('change', handleBguChange);
        } else if (bgus.length <= 1) {
            $myBguSelect.parents('.callout-box').hide();
        }

        //render page based on bgu length
        if (bgus.length > 0) {
            renderBguDetails(0);
        } else {
            renderEmptyBguPage();
        }

        function handleBguChange(ev) {
            renderBguDetails(ev.target.value);
        }

        function renderBguDetails(bguToRenderIndex) {
            const selectedBgu = bgus[bguToRenderIndex].BguName;
            const selectedBguNumber = bgus[bguToRenderIndex].BguNumber;

            //check if the user is a leader in this BGU
            const isLeader = content.ServiceHistory.some((positionHistory) => {
                const isLeader = positionHistory.BGUName === selectedBgu && positionHistory.IsLeader && positionHistory.EndDate === null;
                return isLeader;
            });

            //Render BGU from the array based on index
            $myBgu.html(spinnerHtml);
            $myBguContacts.html(spinnerHtml);

            const $myBguMembersAccordion = $myBguMembers.parents('.accordion');
            if (isLeader) {
                $myBguMembersAccordion.show();
                $myBguMembers.html(spinnerHtml);
                $('.on-this-page__link[href="#My-BGU-Members"]').parent('li').show();
            } else {
                $myBguMembersAccordion.hide();
                $('.on-this-page__link[href="#My-BGU-Members"]').parent('li').hide();
            }

            //Contacts and members render empty if there are no BGUs (for staff)
            if (bgus.length > 0) {
                jQuery.ajax({
                    type: 'GET',
                    data: 'bgu=' + selectedBgu.toLowerCase().toNormalisedName(),
                    url: '/dfes-api/ContactsAPI/GetContactsByBgu',
                    success: function (response) {
                        //console.log('Contact content for selected BGU:');
                        //console.log(response.data);

                        //Render new BGU details from existing 'bgus' array
                        var bguDetailsHtml = getMyBGUTableHtml(bgus[bguToRenderIndex]);
                        $myBgu.html(bguDetailsHtml);
                        //Render new contacts with response data
                        var bguContactsHtml = getContactsHtml(response.data);
                        $myBguContacts.html(bguContactsHtml);

                        //Post render functions
                        svgInliner();
                        checkTableSwipeDisplay();
                    }.bind(this),
                    dataType: 'json',
                });

                if (isLeader) {
                    jQuery.ajax({
                        type: 'GET',
                        data: 'bguNumber=' + selectedBguNumber,
                        url: '/dfes-api/ContactsAPI/GetBguMembers',
                        success: function (response) {
                            try {
                                if (response.exitCode !== 0) {
                                    displayErrorMessage($myBguMembers);
                                    return;
                                }

                                const bguMembers = response.data;
                                var bguMembersHtml = getMembersHtml(bguMembers);
                                $myBguMembers.html(bguMembersHtml);

                                //Post render functions
                                checkTableSwipeDisplay();
                            } catch (error) {
                                console.error('There was an issue generating the My BGU members: ', error);
                                displayErrorMessage($myBguMembers);
                            }
                        }.bind(this),
                        dataType: 'json',
                    });
                }
            }
        }

        function renderEmptyBguPage() {
            //There are no BGUs, render blank page.
            //Handle this separately as an API call blocks us otherwise
            var bguDetailsHtml = getMyBGUTableHtml({});
            $myBgu.html(bguDetailsHtml);

            $myBguContacts.html('<p style="text-align: center;">There are no contacts for the selected BGU</p>');

            $myBguMembers.html(`
                <table class="dfes-o-table">
                    <thead>
                        <tr>
                            <th>Volunteer Name</th>
                            <th>Volunteer ID</th>
                            <th>Position (Rank)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                        </tr>
                    </tbody>
                </table>
            `);
        }
    }
};

//Render helpers

function getMyBGUTableHtml(bgu) {
    function getBGURow(label, value, isEmail = false) {
        if (value !== null && typeof value !== 'undefined' && value.length > 0) {
            var renderVal = value;
            if (isEmail) {
                renderVal = `<a href="mailto:${value}">${value}</a>`;
            }
            return `
                <tr>
                    <th scope="row">${label}</th>
                    <td>${renderVal}</td>
                </tr>
            `;
        } else {
            return `
                <tr>
                    <th scope="row">${label}</th>
                    <td>-</td>
                </tr>
            `;
        }
    }

    function getLogoImg(bguType, alt=null) {
        return `<img class="logo" src="/assets/static/images/content/bgu/logo-${bguType}.png" alt="${alt}" />`;
    }

    var phoneArr = [];
    if (bgu.BusinessPhone !== null && typeof bgu.BusinessPhone !== 'undefined' && bgu.BusinessPhone.length > 0) {
        phoneArr.push('Phone (work): ' + bgu.BusinessPhone);
    } else {
        phoneArr.push('Phone (work): -');
    }
    if (bgu.Mobile !== null && typeof bgu.Mobile !== 'undefined' && bgu.Mobile.length > 0) {
        phoneArr.push('Mobile: ' + bgu.Mobile);
    } else {
        phoneArr.push('Mobile: -');
    }
    if (bgu.Fax !== null && typeof bgu.Fax !== 'undefined' && bgu.Fax.length > 0) {
        phoneArr.push('Fax: ' + bgu.Fax);
    } else {
        phoneArr.push('Fax: -');
    }

    // BGU Logo
    var bguLogo = '';
    var bguTitle = '';
    switch (bgu.BguType) {
        case 'SES':
            bguTitle = 'State Emergency Service WA';
            bguLogo = getLogoImg('ses', bguTitle);
            break;
        case 'YES':
            bguTitle = 'Youth in Emergency Services';
            bguLogo = getLogoImg('esypb', bguTitle);
            break;
        case 'VFES':
            bguTitle = 'Volunteer Fire & Emergency Services WA';
            bguLogo = getLogoImg('vfes', bguTitle);
            break;
        case 'VFRS':
            bguTitle = 'Volunteer Fire and Rescue Service';
            bguLogo = getLogoImg('wafr', bguTitle);
            break;
        case 'BFB':
            bguTitle = 'Bush Fire Service WA';
            bguLogo = getLogoImg('bfswa', bguTitle);
            break;
        case 'VMRS':
            bguTitle = 'Volunteer Marine Rescue Services WA';
            bguLogo = getLogoImg('vmrs', bguTitle);
            break;
        case 'ESCC':
            bguTitle = 'Emergency Services Cadet Corps';
            bguLogo = getLogoImg('escc', bguTitle);
            break;
        case 'ERT':
            bguTitle = 'ERT';
            break;
        case 'PRIV':
        case 'PVT':
            bguTitle = 'PVT';
            break;
        case 'DFES':
        case 'DFES SP':
            bguTitle = 'DFES USAR';
            break;
    }

    const officerName = bgu.DistrictOfficer !== null && typeof bgu.DistrictOfficer !== 'undefined' ? bgu.DistrictOfficer.Name : null;

    return `
        <div class="u-table-container--responsive"> 
            <div class="table-swipe"><span>Swipe to see more</span></div>    
            <table class="dfes-o-table my-bgu-details">
                <tbody>
                    <tr>
                        <td colspan="2">
                            <div class="my-bgu-details__header"> 
                                <div class="my-bgu-details__logo-wrapper">
                                ${bguLogo}
                                </div>
                                <h3 class="bgu-heading">${bguTitle}</h3>
                            </div>
                        </td>
                    </tr>
                ${getBGURow('Service Name', bgu.BguName)}

                ${getBGURow('BGU Location Address', bgu.StreetAddress)}
                ${getBGURow('BGU Postal Address', bgu.PostalAddress)}
        
                ${getBGURow('BGU Phone Numbers', phoneArr.join('<br />'))}
        
                ${getBGURow('BGU email', bgu.Email, true)}
                ${getBGURow('District Officer Details', officerName)}
                ${getBGURow('Region Name', bgu.Region)}

                </tbody>
            </table> 
        </div>
	`;
}

function getContactsHtml(data) {
    const contacts = data.filter((item) => {
        return item.subHeading !== 'BGU Contact';
    });

    if (contacts.length > 0) {
        return `
            <div class="row row--flex">
                ${contacts.map((contact) => getContactHTML(contact)).join('')}
            </div>
        `;
    } else {
        return '<p style="text-align: center;">There are no contacts for the selected BGU</p>';
    }
}

function getMembersHtml(data) {
    function getMemberHtml(member) {
        return `
            <tr>
                <td>${member.givenName} ${member.surname}</td>
                <td>${member.position.ServiceNo}</td>
                <td>${member.position.position}</td>
            </tr>
        `;
    }

    return `
        <div class="u-table-container--responsive"> 
            <table class="dfes-o-table">
                <thead>
                    <tr>
                        <th>Volunteer Name</th>
                        <th>Volunteer ID</th>
                        <th>Position (Rank)</th>
                    </tr>
                </thead>
                <tbody id="my-bgu-members-table-body">
                    ${data.map((contact) => getMemberHtml(contact)).join('')}
                </tbody>
            </table>
        </div>
    `;
}
